<template>
  <div>
    <el-select
      v-model="city_value"
      filterable
      :size="size"
      :placeholder="placeTitle"
      :disabled="!countryId || disabled"
      :clearable="clearable"
    >
      <el-option
        v-for="item in cityList"
        :key="item.id"
        :label="item.name"
        :value="item.id"
        >{{ item.name }}</el-option
      >
    </el-select>
  </div>
</template>

<script>
import { getCityListByCountryId } from "~/baseUtils/AddressConfig";
export default {
  props: {
    placeTitle: {
      type: String,
      default: "City",
    },
    value: {
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    countryId: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
     type:{
       type:Number || String,
       default:0,
    }
  },
  mounted() {
    this.city_value = this.value;
    this.getCityList();
  },
  data() {
    return {
      city_value: null,
      cityList: [],
    };
  },
  methods: {
    async getCityList() {
      if (!this.countryId) {
        this.cityList = [];
        return;
      }
      let cityList = await this.$store.dispatch(
        "baseStore/getMeetingEnrollCity",
        {
          meeting_id: this.MEETING_ID,
          country: this.countryId,
          query_type:this.type
        }
      );
      let locale = this.locale || this.LOCALE;
      let _cityList = cityList.data.map((item) => {
        return {
          id: item.city_id,
          name: item[`city_name_${locale}`],
        };
      });
      this.cityList = _cityList;
    },
  },
  watch: {
    countryId(nv) {
      if (nv) {
        this.city_value = null;
        this.getCityList();
      } else {
        this.$emit("input", null);
      }
    },
    value(nv) {
      this.city_value = nv;
    },
    city_value(nv) {
      this.$emit("input", nv);
    },
  },
};
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
  .el-input__icon {
    color: #c0c4cc;
  }
}
</style>